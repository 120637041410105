import { APP_CONFIGS } from "../constants";
import { clearCookies } from "../helpers/utils";
import {
    IAuthTokens,
    IFirstLoginRequest,
    ILogin,
    IPasswordChange,
    IPasswordRecover,
    IRequest,
    IRequestError
} from "../interfaces/authentication.interface";
import { basicRequest, request } from "./requests.service";

export const login = async (
    credentials: ILogin
): Promise<IRequest<IAuthTokens | IRequestError>> => {
    const headers = {
        "Content-Type": "application/json"
    };
    try {
        const response = await request.post<IAuthTokens>(`/${APP_CONFIGS.api.base}/login`, credentials, {
            headers
        });

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const getNewToken = async (
    refreshToken: string
): Promise<IRequest<IAuthTokens | IRequestError>> => {
    const headers = {
        "Content-Type": "application/json"
    };
    try {
        const response = await request.post<IAuthTokens>(
            `/auth/refresh-token`,
            { refreshToken },
            {
                headers
            }
        );

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const setNewPassword = async (
    passwords: IPasswordChange
): Promise<IRequest<any | IRequestError>> => {
    try {
        const response = await request.post<any>(`/${APP_CONFIGS.api.base}/change-password`, {
            ...passwords
        });

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const forgotPassword = async (email: string): Promise<IRequest<any | IRequestError>> => {
    try {
        const response = await basicRequest.post<any>(`/${APP_CONFIGS.api.base}/recovery-password/step-1`, {
            email
        });

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: true, data: error as IRequestError };
    }
};

export const validateCode = async (code: string): Promise<IRequest<any | IRequestError>> => {
    try {
        const response = await basicRequest.get<any>(`/${APP_CONFIGS.api.base}/validate/${code}`);

        if (response.data.status) throw new Error(response.data.message);

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const validateRecoveryCode = async (code: string): Promise<IRequest<any | IRequestError>> => {
    try {
        const response = await basicRequest.get<any>(`/${APP_CONFIGS.api.base}/recovery-password/validate/${code}`);

        if (response.data.status) throw new Error(response.data.message);

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const setFirstLogin = async (
    data: IFirstLoginRequest
): Promise<IRequest<any | IRequestError>> => {
    try {
        const response = await basicRequest.post<any>(`/${APP_CONFIGS.api.base}/first-login/`, data);

        if (response.data.status) throw new Error(response.data.message);

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const recoverPassword = async (
    data: IPasswordRecover
): Promise<IRequest<any | IRequestError>> => {
    try {
        const response = await basicRequest.post<any>(`/${APP_CONFIGS.api.base}/recovery-password/step-2`, data);

        if (response.data.status) throw new Error(response.data.message);

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const logout = async (): Promise<IRequest<null | IRequestError>> => {
    try {
        await request.get<any>(`/auth/logout`);
        clearCookies();

        return { success: true, data: null };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};
